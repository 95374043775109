<!-- <ul *ngFor="let todo of todos">
    <li> {{ todo.title }}</li>
</ul> -->

<app-add-todo
    (addTodo)="addTodo($event)"
>
</app-add-todo>
<app-todo-item 
    *ngFor="let todo of todos" 
    [todo]="todo"
    (deleteTodo)="deleteTodo($event)"
>
</app-todo-item>