<header class="header">
    <!-- Social Links -->
    <nav class="social-nav">
        <ul>
            <li><a href="http://www.facebook.com/triparoundpk/" target="_blank"><img src="../../../../assets/images/icon-facebook.png" /></a></li>
            <li><a href="https://www.instagram.com/triparoundpk/" target="_blank"><img src="../../../../assets/images/icon-instagram.png" /></a></li>
            <li><a href="https://twitter.com/TripAroundpk" target="_blank"><img src="../../../../assets/images/icon-twitter.png" /></a></li>
            <!-- <li><a href="#"><img src="../../../../assets/images/icon-google.png" /></a></li> -->
            <!-- <li><a href="#"><img src="../../../../assets/images/icon-linkedin.png" /></a></li> -->
            <!-- <li><a href="#"><img src="../../../../assets/images/icon-pinterest.png" /></a></li> -->
        </ul>
    </nav>
</header>