<!-- About Page -->
<section class="content show" id="about">
    <h1>About</h1>
    <h5>Here's a little about what we're up to.</h5>
    <p>
        <a routerLink="/"><b>TripAround.pk</b></a> provides the services regarding tourism in Pakistan. <a routerLink="/"><b>TripAround.pk</b></a>
        intends to provide best possible services according to the consumers interests. We focus on the customised trips, depending upon your
        budget, moods and routes.
    </p>
</section>
<app-quotes></app-quotes>
