import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Todo } from '../models/Todo';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})

export class TodoService {
  todosUrl:string = "http://jsonplaceholder.typicode.com/todos";
  todosLimit:string = "?_limit=5";
  constructor(private http:HttpClient) { }

  //Get Todos
  getTodos():Observable<Todo[]>{
    return this.http.get<Todo[]>(this.todosUrl + this.todosLimit);
  }

  //Toggle Completed
  toggleCompleted(todo:Todo):Observable<any>{
    const url = this.todosUrl + "/" + todo.id;
    return this.http.put(url, todo, httpOptions)
  }

  //Delete Todo
  deleteTodo(todo:Todo):Observable<Todo>{
    const url = this.todosUrl + "/" + todo.id;
    return this.http.delete<Todo>(url, httpOptions);
  }

  addTodo(todo:Todo):Observable<Todo>{
    return this.http.post<Todo>(this.todosUrl, todo, httpOptions);
  }
  
  getTodosLocal(){
    return [
      {
        id: 1,
        title: 'Todo One',
        completed: false
      },
      {
        id: 2,
        title: 'Todo Two',
        completed: true
      },
      {
        id: 3,
        title: 'Todo Three',
        completed: false
      }
    ]
  }
}
