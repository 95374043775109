<div>
    <!-- <h1>TodoList</h1>
    <nav>
        <a routerLink="/">Home</a> | <a routerLink="/about">About</a>
    </nav> -->
    <div id="logo">triparound.pk</div>
    <nav class="main-nav">
        <ul>
            <li><a routerLink="/" (click)="toggleClass(0);" [ngClass]="status[0] ? 'active' : 'inactive'">Home</a></li>
            <li><a routerLink="/about" (click)="toggleClass(1);" [ngClass]="status[1] ? 'active' : 'inactive'">About</a></li>
            <li><a routerLink="/contact" (click)="toggleClass(2);" [ngClass]="status[2] ? 'active' : 'inactive'">Contact Us</a></li>
        </ul>
    </nav>
</div>