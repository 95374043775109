import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  status: boolean[]= [true, false, false];
  constructor() { }

  ngOnInit(): void {
  }

  toggleClass(id: number){
    for (let index = 0; index < this.status.length; index++) {
      if (index === id)
        this.status[index] = true;
      else
        this.status[index] = false;
    }
  }
}
