<!-- Home Page -->
<section class="content show" id="home">
    <h1>Welcome</h1>
    <h5>Our new site is coming soon!</h5>
    <p>
        Welcome to <a routerLink="/"><b>TripAround.pk</b></a> Our Website is under development and our services would be in your assistance
         through our website soon meanwhile we are serving through direct contact only, to avail our services please 
         find our contact on contac Us page. We intend to serve you in the best possible way.
    </p>
</section>
<app-quotes></app-quotes>